var data = [{
    'label': 'Henüz işlem yapılmadı',
    'id': 1,
    'UserPermissionId': 1,
    'eligible_status': [1, 3, 4, 5, 6, 7, 8, 9, 10, 18],
    'background_color': '#fae482e0'
  },
  {
    'label': 'Tedavi burada uygulanacak (ilaç istemi yapılarak)',
    'id': 2,
    'UserPermissionId': 2,
    'eligible_status': [1, 3, 4, 5, 6, 7, 8, 9, 10, 18],
    'background_color': '#47D4DC'
  },
  {
    'label': 'Tedavi burada uygulanacak (ilaç istemi yapılmadan)',
    'id': 3,
    'UserPermissionId': 3,
    'eligible_status': [1, 2, 4, 5, 6, 7, 8, 9, 10, 18],
    'background_color': '#47D4DC'
  },
  {
    'label': 'Tedavi burada uygulanacak (İlaç istemi yapılmadan. (Klinik araştırma hastası))',
    'id': 4,
    'UserPermissionId': 4,
    'eligible_status': [1, 2, 3, 5, 6, 7, 8, 9, 10, 18],
    'background_color': '#47D4DC'
  },
  {
    'label': 'Tedavi başka bir merkezde uygulanmış',
    'id': 5,
    'UserPermissionId': 5,
    'eligible_status': [1, 2, 3, 4, 6, 7, 8, 9, 10, 18],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Hasta ilacı evde aldığını ifade etti',
    'id': 6,
    'UserPermissionId': 6,
    'eligible_status': [1, 2, 3, 4, 5, 7, 8, 9, 10, 18],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Tedavi onayı geri alındı',
    'id': 7,
    'UserPermissionId': 7,
    'eligible_status': [2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18],
    'background_color': '#fae482e0'
  },
  {
    'label': 'Bu ilaç bugünde uygulanmayacak. İptal edildi',
    'id': 8,
    'UserPermissionId': 8,
    'eligible_status': [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 18],
    'background_color': '#f86c6b'
  },
  {
    'label': 'Hasta bu tedaviyi daha önceden aldı',
    'id': 9,
    'UserPermissionId': 9,
    'eligible_status': [1, 2, 3, 4, 5, 6, 7, 8, 10, 18],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Hasta bu tedaviyi kendisi uygulayacak',
    'id': 10,
    'UserPermissionId': 10,
    'eligible_status': [1, 2, 3, 4, 5, 6, 7, 8, 9, 18],
    'background_color': '#fae482e0'
  },
  {
    'label': 'Radyoterapi uygulandı',
    'id': 11,
    'UserPermissionId': 11,
    'eligible_status': [1, 7, 8, 18],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerildiği gibi uygulanmıştır. Aynı şekilde devam edilecek',
    'id': 12,
    'UserPermissionId': 12,
    'eligible_status': [1, 7],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerildiği gibi uygulanmıştır',
    'id': 13,
    'UserPermissionId': 13,
    'eligible_status': [1, 7],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerilenden farklı uygulanmıştır. Aynı şekilde devam edilecek',
    'id': 14,
    'UserPermissionId': 14,
    'eligible_status': [1, 7],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Önerilenden farklı uygulanmıştır',
    'id': 15,
    'UserPermissionId': 15,
    'eligible_status': [1, 7],
    'background_color': '#4dbd74'
  },
  {
    'label': 'Tedavi uygulanmamıştır',
    'id': 16,
    'UserPermissionId': 16,
    'eligible_status': [1, 7],
    'background_color': '#f86c6b'
  },
  {
    'label': 'Ürün seçimi yapıldı',
    'id': 17,
    'UserPermissionId': 17,
    'eligible_status': [2, 18, 19, 21, 22, 23, 24],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'Ürün seçimi iptal edildi',
    'id': 18,
    'UserPermissionId': 18,
    'eligible_status': [17, 19, 21, 22, 23, 24],
    'background_color': '#47D4DC'
  },
  {
    'label': 'Ürün seçimleri düzenlendi',
    'id': 19,
    'UserPermissionId': 19,
    'eligible_status': [17, 21, 22, 23, 24],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verildi',
    'id': 20,
    'UserPermissionId': 20,
    'eligible_status': [17, 19, 21, 22, 23, 24, 28, 29],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi',
    'id': 21,
    'UserPermissionId': 21,
    'eligible_status': [17, 19, 20, 22, 23, 24, 28, 29],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi. (Stokta yok)',
    'id': 22,
    'UserPermissionId': 22,
    'eligible_status': [17, 19, 20, 21, 23, 24, 28, 29],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi. (Hatalı doz?)',
    'id': 23,
    'UserPermissionId': 23,
    'eligible_status': [17, 19, 20, 21, 22, 24, 28, 29],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç verilemedi. (Sistemde ilacın kaydı yok)',
    'id': 24,
    'UserPermissionId': 24,
    'eligible_status': [17, 19, 20, 21, 22, 23, 28, 29],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç hazırlandı. (Manuel)',
    'id': 25,
    'UserPermissionId': 25,
    'eligible_status': [3, 4, 20, 26, 27, 28, 29, 31],
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hazırlandı. (Tam otomatik sistemde)',
    'id': 26,
    'UserPermissionId': 26,
    'eligible_status': [3, 4, 20, 25, 27, 28, 29, 31],
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç kullanıma hazır bir ürün',
    'id': 27,
    'UserPermissionId': 27,
    'eligible_status': [3, 4, 20, 25, 26, 28, 29, 31],
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hazırlanamadı',
    'id': 28,
    'UserPermissionId': 28,
    'eligible_status': [3, 4, 20, 25, 26, 27, 29, 31],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'İlaç hazırlanamadı. (Doz hatası?)',
    'id': 29,
    'UserPermissionId': 29,
    'eligible_status': [3, 4, 20, 25, 26, 27, 28, 31],
    'background_color': '#9e9e9e'
  },
  {
    'label': 'Hasta ilaç karşılaştırması başarıyla tamamlandı',
    'id': 30,
    'UserPermissionId': 30,
    'eligible_status': [25, 26, 27, 31, 34, 35, 36],
    'background_color': '#47D4DC'
  },
  {
    'label': 'Bu ilaç hastaya ait değil',
    'id': 31,
    'UserPermissionId': 31,
    'eligible_status': [25, 26, 27, 30, 34, 35, 36],
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlacın tamamı uygulandı',
    'id': 32,
    'UserPermissionId': 32,
    'eligible_status': [30, 33, 34, 35, 36],
    'background_color': '#4dbd74'
  },
  {
    'label': 'İlaç uygulandı. Fakat tamamı uygulanamadı',
    'id': 33,
    'UserPermissionId': 33,
    'eligible_status': [30, 32, 34, 35, 36],
    'background_color': '#4dbd74'
  },
  {
    'label': 'İlaç hastaya uygulanamadı',
    'id': 34,
    'UserPermissionId': 34,
    'eligible_status': [30, 32, 33, 35, 36],
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hastaya uygulanamadı. (Damar yolu tespit edilemedi)',
    'id': 35,
    'UserPermissionId': 35,
    'eligible_status': [30, 32, 33, 34, 36],
    'background_color': '#47D4DC'
  },
  {
    'label': 'İlaç hastaya uygulanamadı. (Hasta tedaviyi reddetti)',
    'id': 36,
    'UserPermissionId': 36,
    'eligible_status': [30, 32, 33, 34, 35],
    'background_color': '#47D4DC'
  }
];

export { data as options };
