<template>
  <div>
    <b-row>
      <b-col sm="12" md="12">
        <b-dropdown variant="white" size="sm">
          <template slot="button-content"> İlaç Onay </template>
          <div style="overflow-x: hidden; overflow-y: auto; height: 300px; width: 500px;">
            <template v-for="(button_option, button_option_index) in d_drugApprovalOption">
              <b-dropdown-item v-if="user.perms && user.perms.indexOf(button_option.UserPermissionId) !== -1  && (button_option.eligible_status.length === 0 || button_option.eligible_status.indexOf(p_applicationDrugData.DrugApplicationStatusId) !== -1)" @click="f_approveDrug(button_option)">
                {{ button_option.label }}
              </b-dropdown-item>
            </template>
          </div>
        </b-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
/*import {
  default as BrandSelection
} from './BrandSelection';
*/
import {
  default as Modal
} from '@/components/widgets/Modal';
import moment from 'moment';

export default {
  name: 'TreatmentDrugButtons',
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  components: {
    Modal,
    // BrandSelection,
    // PharmacyWidget
  },
  props: {
    p_treatmentData: {
      type: Object,
      required: true
    },
    p_day: {
      type: String,
      required: true
    },
    p_applicationDrugData: {
      type: Object,
      required: true
    },
    p_applicationDrugIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return { // variables
      d_drugApprovalOption: require('@/options/drug_approval_option').options,
      user: {
        'perms': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
      },
    };
  },
  beforeCreate: function () {},
  created: function () {
    // this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_approveDrug: function (button_option) {
      console.log('f_approveDrug...');
      this.p_applicationDrugData.DrugApplicationStatusId = button_option.id;
      this.p_applicationDrugData.DrugApplicationStatusLabel = button_option.label;
    }
  },
  filters: {},
  watch: {},
};

</script>

<style>
.bg-danger-tratment {
  color: #fff !important;
  background-color: #f86c6b !important;
  cursor: not-allowed;
}

.treatment-actions-btns {
  width: 44px;
}

.tratmnet-logs-header {
  background: #cfd8dc;
  padding: 5px;
}

.tratmnet-logs-sub {
  background: #cfd8dc;
  padding: 5px;
  padding-left: 30px;
  border-top: 1px solid #21a8d8;
}

.treatment-log-sub-title {
  margin: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: red;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  line-height: 22px;
  text-align: center;
  background: #21a8d8;
}

,
.toxicilty-lists {
  background: rgb(236, 239, 242);
  border-radius: 5px;
  padding: 5px;
}

.treatment-active {
  background-color: #b3ffcc !important;
}

.treatment-waiting {
  background-color: #ccf2ff !important;
}

.treatment-finished {
  background-color: #ff8080 !important;
}

.table-drug {
  background-color: #e6ffff !important;
}

#table-scroll {
  overflow: auto;
}

</style>

