var data = [{
  'id': '1',
  'PatientDiagnosisId': '1',
  'PatientDiagnosisName': 'C50 Meme Malign Neoplazm',
  'TreatmentTypeId': '1',
  'TreatmentTypeName': 'Metastatik',
  'CreatedBy': '1',
  'CreatedByName': 'Ad Soyad',
  'TreatmentStatusId': '1',
  'TreatmentStatusIdName': 'Sonlandırıldı',
  'treatment_name': 'cf => sisplatin (75 mg/m2 , 1.gün)  , fluorourasil (5000 mg/m2 , infüzör pompası ile , 1.gün)  [21 gündebir , 6kür]',
  'step_no': 1,
  'start_date': '2021-06-01T09:00:00.000000',
  'end_date': '2021-12-01T09:00:00.000000'
}, {
  'id': '2',
  'PatientDiagnosisId': '1',
  'PatientDiagnosisName': 'C50 Meme Malign Neoplazm',
  'TreatmentTypeId': '1',
  'TreatmentTypeName': 'Metastatik',
  'CreatedBy': '1',
  'CreatedByName': 'Ad Soyad',
  'TreatmentStatusId': '1',
  'TreatmentStatusIdName': 'Aktif',
  'treatment_name': 'Paklitaksel (80 mg/m2 , 1.gün) [21 gündebir , 6kür]',
  'step_no': 2,
  'start_date': '2022-01-01T09:00:00.000000',
  'end_date': ''
}];

export { data as example_data };
